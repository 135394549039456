body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

#risk-level-bar{
  height: 500px !important;
  width:938px !important;
}
#levelPresentation{
  height: 6% !important;
  margin-top: 20px;
}

#occupationLevel{
  margin-top: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
table, th, td {
  border:  2px solid #dee2e6 !important;
  border-collapse: collapse;
}
.textFilter{
  border-bottom:  2px solid #dee2e6 !important;
}
/* thead{
  border: 2px solid #dee2e6 !important
} */
.rowTableCenter{
  vertical-align: middle !important;
}
.textRowCentered{
  text-align: center !important;
}
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 14px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: rgba(41, 22, 111, 1);
}

input:focus + .slider {
  box-shadow: 0 0 1px rgba(41, 22, 111, 1);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.container {
  height: max("100vh", "auto");
}

.slick-prev:before,
.slick-next:before {
  color: black!important;
}

.containerHomepageAdmin{
  border: 2px solid blue;
  border-radius: 50px;
  margin: 25px;
}

.selectedButton{
  font-size: 16px;
    height: 5%;

}

.containerNumber{
  display: flex;
    justify-content: center;
    font-size: 60px;
    align-items: flex-start;
    color: darkgrey;
}

.boxTextHomepage{
  font-size: 14px;
  font-weight: bold;
}

.containerTitleHomepage{
  font-size: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.date-widget {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-family: Arial, sans-serif;
}

.date {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  background-color: #fff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.page-title {
  text-transform: uppercase !important;
  font-size: 16px;
  font-weight: 600;
}